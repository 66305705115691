import { Suspense, lazy, useEffect, useState } from 'react';
import { useSourTraxForm } from '../../../../utils/_form-wrapper';
import Loader from '../../../Loader';
import { infoEmail, officeTelDisplay, supportEmail } from '../../../_constants';
import { useReferer } from '../../../nanostore/useReferer';
import { APIService } from '../../API/ApiService';
import SimpleAlert, { type SimpleAlertProps } from '../SimpleAlert/SimpleAlert';

const Loading = lazy(() => import('@wayste/sour-ui').then((module) => ({ default: module.Loading })));
const TextField = lazy(() => import('@wayste/sour-ui').then((module) => ({ default: module.TextField })));
const Textarea = lazy(() => import('@wayste/sour-ui').then((module) => ({ default: module.Textarea })));

interface FormProps {
    name: string;
    email: string;
    message: string;
    phoneNumber: string;
}
export interface ContactForm {
    formName: string;
    buttonText?: string;
    phoneOptional?: boolean;
    theme?: 'light' | 'dark';
    buttonID?: string;
    formID?: string;
}
const ContactForm = ({ formName, buttonText, phoneOptional, theme = 'light', buttonID, formID }: ContactForm) => {
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState<SimpleAlertProps & { open: boolean }>();
    const { getReferer } = useReferer();
    const [phoneNumberMaskerLazy, setPhoneNumberMaskerLazy] = useState<((value: string) => string) | null>(null); // State for masker function

    const handleMaskerImport = async () => {
        const { phoneNumberMasker } = await import('@wayste/sour-ui');
        setPhoneNumberMaskerLazy(() => phoneNumberMasker);
    };

    useEffect(() => {
        handleMaskerImport();
    }, []);

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useSourTraxForm(
        formName,
        undefined,
        {
            mode: 'all',
            shouldFocusError: false,
            defaultValues: {
                name: '',
                email: '',
                message: '',
                phoneNumber: '',
            },
        },
        {
            disableFieldTracking: true,
        },
    );

    const onSubmit = async (data: FormProps) => {
        const trimmedEmail = data.email.trim().toLowerCase();
        const htmlMessage = `Name: ${data.name}<br />Email: ${trimmedEmail}<br />Phone: ${data.phoneNumber}<br />Message: ${
            data.message
        }<br />Search params: ${window.location.href}<br />Referring Domain: ${getReferer().url}`;
        const textMessage = htmlMessage.split('<br />').join('\n\n');

        try {
            setIsLoading(true);
            await APIService.Email.sendEmail({
                endPoint: 'send-email',
                emailData: {
                    toAddress: infoEmail,
                    sender: supportEmail,
                    subject: 'Sourgum Contact Form',
                    replyToAddress: trimmedEmail,
                    htmlMessage: htmlMessage,
                    textMessage: textMessage,
                },
            });
            if (window) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'Form_Submit_Network_Success',
                    formName: formName,
                });
            }
            setAlert({
                open: true,
                msg: 'Someone will be in touch with you shortly.',
                severity: 'success',
                title: 'Success',
            });
        } catch (error) {
            if (window) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'Form_Submit_Network_Error',
                    formName: formName,
                });
            }
            setAlert({
                open: true,
                msg: 'An error has occurred, please try again or contact us at ' + officeTelDisplay,
                severity: 'error',
                title: 'Error',
                rightButton: {
                    text: 'Okay',
                    onClick: () => {
                        window.location.href = '/';
                    },
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    const submit = async (e: React.FormEvent<HTMLFormElement> | undefined) => {
        e?.preventDefault();
        handleSubmit(
            (data) => {
                onSubmit(data);
            },
            (err) => {
                const keys = Object.keys(err);
                const first = keys?.[0];
                if (!first) {
                    setAlert({
                        open: true,
                        msg: 'An error has occurred, please refresh or contact support. code:98SKYC',
                        severity: 'error',
                        title: 'Error',
                    });
                    return;
                }
                const error = document.getElementById(first);
                error?.focus({ preventScroll: true });

                error?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
            },
        )();
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <SimpleAlert
                {...alert}
                open={alert?.open || false}
                closeDialog={() => setAlert({ ...(alert || { msg: '', title: '', severity: 'info' }), open: false })}
            />
            <Suspense
                fallback={
                    <div className="flex h-[500px] w-full flex-col items-center justify-center">
                        <Loading aria-label="Loading form..." />
                        <p>Loading...</p>
                    </div>
                }
            >
                <form
                    onSubmit={submit}
                    className={`flex w-full flex-col gap-4 ${theme === 'dark' ? 'text-neutral-400' : 'text-neutral-600'}`}
                    id={formID}
                >
                    <div>
                        <label htmlFor="name" className="px-1 text-lg font-normal leading-relaxed">
                            Name
                        </label>
                        <TextField
                            inputProps={{ ...register('name', { required: 'Your name is required' }) }}
                            error={errors['name']}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="px-1 text-lg font-normal leading-relaxed">
                            Email Address
                        </label>
                        <TextField
                            inputProps={{
                                ...register('email', {
                                    validate: {
                                        isEmail: (val) => {
                                            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                            const validated = String(val).match(re);
                                            if (!validated) return 'Must be a valid email.';
                                            return true;
                                        },
                                    },
                                }),
                                onKeyDown: (e) => {
                                    if (e.key === ' ' || e.keyCode === 32) {
                                        e.preventDefault();
                                    }
                                },
                            }}
                            error={errors['email']}
                            type="email"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="phoneNumber" className="px-1 text-lg font-normal leading-relaxed">
                            Phone Number{phoneOptional && ' (optional)'}
                        </label>
                        <TextField
                            inputProps={{
                                ...register('phoneNumber', {
                                    onChange: (e) => {
                                        setValue(
                                            'phoneNumber',
                                            phoneNumberMaskerLazy ? phoneNumberMaskerLazy(e.target.value) : e.target.value,
                                        );
                                    },
                                    validate: {
                                        isPhoneFormat: (v) => {
                                            if (!v) return;
                                            const re = /^\(\d{3}\)\s\d{3}-\d{4}$/;
                                            const validated = String(v).match(re);
                                            if (!validated) return 'Must be a valid phone number, e.g. (555) 867-5309';
                                            return true;
                                        },
                                        minLength: (v) => {
                                            if (!v) return;
                                            const onlyNumbers = v?.match(/\d+/g);
                                            if (onlyNumbers?.join('').length !== 10) {
                                                return 'Valid phone number must be at least 10 digits.';
                                            }
                                            return true;
                                        },
                                    },
                                    required: !phoneOptional && 'Phone number is required',
                                }),
                                autoComplete: 'tel-national',
                            }}
                            error={errors?.phoneNumber}
                            placeholder="___ ___-____"
                            startAdornment="+1"
                        />
                    </div>
                    <div>
                        <label htmlFor="message" className="px-1 text-lg font-normal leading-relaxed">
                            Share Relevant Details
                        </label>
                        <Textarea
                            inputProps={{ ...register('message', { required: 'A message is required' }) }}
                            error={errors['message']}
                            required
                        />
                    </div>
                    <button type="submit" className="btn-primary mt-4 w-full" id={buttonID} aria-label="Submit">
                        {buttonText || 'Submit'}
                    </button>
                </form>
            </Suspense>
        </>
    );
};

export default ContactForm;
